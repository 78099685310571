import { useEffect, useRef } from 'react';

const INACTIVITY_LIMIT = 4 * 60 * 60 * 1000;
const CHECK_INACTIVITY_LIMIT = 10 * 1000;
const COOLDOWN_TIME = 5000;

type TimeoutCallback = () => void;

export function useSessionTimeout(onTimeout: TimeoutCallback): void {

    const lastEventTimeRef = useRef(Date.now());

    const checkForInactivity = (): void => {
        const lastActivity = localStorage.getItem('loginTimestamp');
        const now = Date.now();

        if (lastActivity && now - parseInt(lastActivity, 10) > INACTIVITY_LIMIT) {
            localStorage.setItem('logoutEvent', Date.now().toString());
            onTimeout();
        }
    };

    const updateExpiryTime = (): void => {

        const now = Date.now();
        const isLoggedIn = localStorage.getItem('loginTimestamp');

        if (!isLoggedIn) return;

        if (now - lastEventTimeRef.current > COOLDOWN_TIME) {
            lastEventTimeRef.current = now;
            localStorage.setItem('loginTimestamp', now.toString());
        }
    };

    useEffect(() => {

        const interval = setInterval(checkForInactivity, CHECK_INACTIVITY_LIMIT);
        return () => clearInterval(interval);
    }, [onTimeout]);

    useEffect(() => {

        const events = ['click', 'keypress', 'scroll', 'mousemove'];

        const update = (): void => {
            updateExpiryTime();
        };

        events.forEach(event => window.addEventListener(event, update));

        const handleStorageEvent = (event: StorageEvent): void => {
            if (event.key === 'logoutEvent') {
                onTimeout();

                localStorage.removeItem('logoutEvent');
            }
        };

        window.addEventListener('storage', handleStorageEvent);

        return () => {

            events.forEach(event => window.removeEventListener(event, update));
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [onTimeout]);
}
