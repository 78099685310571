import { useTranslation } from 'react-i18next';

import { Field, Select } from '@/components';
import { useCountries } from '@/hooks';

export type CountryFilterProps = {
  readonly value?: string;
  readonly onChange: (value: string) => void;
};

export function CountryFilter({ value, onChange }: CountryFilterProps) {
  const { t } = useTranslation(['home']);

  const { options: countries } = useCountries();

  return (
    <Field id="country" label={t(`Country`)} classes={{ field: 'space-y-2' }}>
      <Select
        id="country"
        size="md"
        color="primary"
        placeholder={t(`Any country`)}
        options={countries}
        value={value}
        registration={{
          name: 'country',
          onChange: (event) => {
            onChange(event.target.value);
            return Promise.resolve();
          },
        }}
      />
    </Field>
  );
}
