import { createApi } from '@reduxjs/toolkit/query/react';

import { BASE_URL, API_BASE_URL } from '@/config';
import { Route } from '@/constants';

import { baseQueryHandler } from './base-query';
import {
  AcceptInvitationRequest,
  LoginRequest,
  LoginResponse,
  PasswordRequest,
  RegisterRequest,
  ResetPasswordRequest,
  VerifyUserRequest,
} from './types';

export const api = createApi({
  reducerPath: 'api/clients',
  baseQuery: baseQueryHandler,
  tagTypes: ['Users', 'Companies', 'Projects', 'Opportunities', 'Offers', 'Deals'],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (data: LoginRequest) => ({ url: `auth/login`, method: 'POST', body: { ...data, mode: 'session' } }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: `auth/logout`, method: 'POST', body: { mode: 'session' } }),
    }),
    passwordRequest: builder.mutation<void, PasswordRequest>({
      query: ({ email }: PasswordRequest) => ({
        url: `accounts/password/request`,
        method: 'POST',
        body: {
          email,
          reset_url: `${BASE_URL}${Route.ResetPassword}`,
          reset_pwd_url: `${BASE_URL}${Route.ResetPassword}`,
          verify_url: `${BASE_URL}${Route.VerifyEmailAddress}`,
          invited_url: `${BASE_URL}${Route.InvitationSignUp}`,
          api_url: `${API_BASE_URL}`
        },
      }),
    }),
    passwordReset: builder.mutation<void, ResetPasswordRequest>({
      query: ({ token, password }: ResetPasswordRequest) => ({
        url: `auth/password/reset`,
        method: 'POST',
        body: { token, password },
      }),
    }),
    register: builder.mutation<void, RegisterRequest>({
      query: (data: RegisterRequest) => ({
        url: `accounts/create`,
        method: 'POST',
        body: { ...data, url: `${BASE_URL}${Route.VerifyEmailAddress}` },
      }),
    }),
    acceptInvitation: builder.mutation<void, AcceptInvitationRequest>({
      query: (data: AcceptInvitationRequest) => ({
        url: `users/invite/accept`,
        method: 'POST',
        body: data,
      }),
    }),
    verifyUser: builder.mutation<void, VerifyUserRequest>({
      query: (data: VerifyUserRequest) => ({
        url: `accounts/verify-user`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
