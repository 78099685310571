import { useState } from 'react';

import { AnimationShell, Icon, Typography } from '@/components';
import { useCollections } from '@/hooks/use-collections';
import { cn } from '@/utils/styles';

import { getIconByCollection } from '../../helpers';
import { useProjectDetails } from '../../hooks/use-project-details';
import { useUpdateTechnology } from '../../hooks/use-update-technology';
import { ProjectTechnology } from '../../types';

import { AttachmentsCollapsable, AttachmentsDrawer } from './attachments';
import { BasicDesignCollapsable, BasicDesignDrawer } from './basic-design';
import { CapexOpexCollapsable, CapexOpexDrawer } from './capex-opex';
import { ConnectionInfrastructureCollapsable } from './connection-infrastructure';
import { ConnectionInfrastructureDrawer } from './connection-infrastructure/connection-infrastructure-drawer';
import { GeneralCollapsable, GeneralDrawer } from './general';
import { LandRightsCollapsable, LandRightsDrawer } from './land-rights';
import { OthersCollapsable, OthersDrawer } from './others';
import { PermitCollapsable } from './permits';
import { PermitsDrawer } from './permits/permits-drawer';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export type ProjectViewProps = {
  readonly id: string;
  readonly opportunityId: string;
  readonly isPaid?: boolean;
};

export function ProjectView({ id, opportunityId, isPaid = false }: ProjectViewProps) {
  const { project, normalizedProject } = useProjectDetails(id);
  //   NOTE: This code hide Project section when user is free.
  //   const { isPremium } = useCompanyRole();
  const projectBelongsToMyCompany = useBelongsToMyCompany(normalizedProject?.company);
  //   const isVisible = projectBelongsToMyCompany || isPremium || isPaid;
  const isVisible = true;

  const isEditable = projectBelongsToMyCompany && normalizedProject?.isDraft;
  //const isEditable = true;

  const [drawer, setDrawer] = useState<string | null>(null);

  const { kv: collections } = useCollections();

  const { onSubmitItem, onSubmitTechnology, deletePermit } = useUpdateTechnology({
    projectId: id,
    opportunityId: opportunityId,
    setDrawer,
  });

  if (!normalizedProject) return null;
  if (!project) return null;

  return normalizedProject.technologies.map((item, index) => (
    <div key={item.id} className="space-y-4">
      <AnimationShell type="opacity" className={cn('inline-flex items-center gap-2.5')}>
        <Icon name={getIconByCollection(item.collection)} className="h-8 w-8 text-primary-700" />

        <AnimationShell type="slide-left">
          <Typography as="h3" size="heading-2" color="primary" weight="semibold">
            {collections[item.collection]}
          </Typography>
        </AnimationShell>
      </AnimationShell>

      <GeneralCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`general-${index}`) : undefined}
        initialExpandedState={!isEditable}
      />
      <GeneralDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `general-${index}`}
        onSubmit={(collection, data, description) => onSubmitItem(collection, data, description)}
        onClose={() => setDrawer(null)}
      />

      <PermitCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`permits-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
        country={project?.country}
      />
      <PermitsDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `permits-${index}`}
        onSubmit={(data, description) => onSubmitTechnology(item.id, data, description)}
        onClose={() => setDrawer(null)}
        country={project?.country}
        deletePermit={deletePermit}
      />

      <BasicDesignCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`basic-design-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
      />
      <BasicDesignDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `basic-design-${index}`}
        onSubmit={(collection, data, description) => onSubmitItem(collection, data, description)}
        onClose={() => setDrawer(null)}
      />

      <LandRightsCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`land-rights-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
      />
      <LandRightsDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `land-rights-${index}`}
        onSubmit={(data, description) => onSubmitTechnology(item.id, data, description)}
        onClose={() => setDrawer(null)}
      />

      <ConnectionInfrastructureCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`connection-infrastructure-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
      />
      <ConnectionInfrastructureDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `connection-infrastructure-${index}`}
        onSubmit={(data, description) => onSubmitTechnology(item.id, data, description)}
        onClose={() => setDrawer(null)}
      />

      <CapexOpexCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`capex-open-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
      />
      <CapexOpexDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `capex-open-${index}`}
        onSubmit={(data, description) => onSubmitTechnology(item.id, data, description)}
        onClose={() => setDrawer(null)}
      />

      <OthersCollapsable
        technology={item}
        onEdit={isEditable ? () => setDrawer(`others-${index}`) : undefined}
        hidden={!isVisible}
        initialExpandedState={!isEditable}
      />
      <OthersDrawer
        technology={project.technologies.at(index) as ProjectTechnology}
        isOpen={drawer === `others-${index}`}
        onSubmit={(data, description) => onSubmitTechnology(item.id, data, description)}
        onClose={() => setDrawer(null)}
      />

      {projectBelongsToMyCompany ? (
        <>
          <AttachmentsCollapsable
            technology={item}
            onEdit={isEditable ? () => setDrawer(`attachments-${index}`) : undefined}
            initialExpandedState={!isEditable}
          />
          <AttachmentsDrawer
            technology={project.technologies.at(index) as ProjectTechnology}
            isOpen={drawer === `attachments-${index}`}
            onSubmit={(data) => {
              onSubmitTechnology(item.id, data);
            }}
            onClose={() => setDrawer(null)}
          />
        </>
      ) : null}
    </div>
  ));
}
