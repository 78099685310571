import { ClassValue } from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { colors, sizes } from './constants';
import { SelectColor, SelectSize } from './types';

export type SelectProps = {
  readonly options: { label: string; value: string }[];
  readonly id?: string;
  readonly size?: SelectSize;
  readonly color?: SelectColor;
  readonly placeholder?: string;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly className?: ClassValue;
  readonly onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export function Select({
  options,
  id,
  size = 'md',
  color = 'primary',
  placeholder,
  className,
  defaultValue,
  value,
  registration,
}: SelectProps) {
  return (
    <select
      id={id}
      className={cn(
        'text-caption group w-full transition',
        'placeholder:text-base-secondary font-normal text-base-primary sm:text-sm sm:leading-6',
        sizes[size],
        colors[color],
        'appearance-none rounded border focus:ring-0 focus:ring-offset-0',
        'disabled:border-neutral-50 disabled:bg-neutral-25 disabled:text-neutral-disabled disabled:placeholder:text-neutral-disabled',
        'disabled:pointer-events-none disabled:cursor-not-allowed',
        className
      )}
      value={value}
      defaultValue={value === undefined ? defaultValue : undefined}
      {...registration}
    >
      {placeholder ? <option value="">{placeholder}</option> : null}

      {options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
}
