import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Field, Form, Input, Select, TextArea, Typography, Icon } from '@/components';
import { usePermitStatus } from '@/features/projects/hooks';
import { Permit, PermitStatus, ProjectTechnology } from '@/features/projects/types';
import { getDateFromQuarterAndYear, getQuarterFromDate, getYearFromDate, nullableStringSetter } from '@/helpers';
import { isBlank } from '@/types';
import { generateLabel } from '@/features/projects/helpers';

export type OtherLicenseProps = {
  id: string | null;
  title: string | null;
  status: PermitStatus | null;
  quarter: string | null;
  year: number | null;
};

export type PermitsFormFields = {
  ivaId: string | null;
  ivaTitle: string | null;
  ivaStatus: string | null;
  ivaQuarter: string | null;
  ivaYear: number | null;
  diaId: string | null;
  diaTitle: string | null;
  diaStatus: string | null;
  diaQuarter: string | null;
  diaYear: number | null;
  aapId: string | null;
  aapTitle: string | null;
  aapStatus: string | null;
  aapQuarter: string | null;
  aapYear: number | null;
  aacId: string | null;
  aacTitle: string | null;
  aacStatus: string | null;
  aacQuarter: string | null;
  aacYear: number | null;
  otherLicenses: Array<OtherLicenseProps>;
  permiting_comments: string | null;
};

export type PermitsFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: { permits: Permit[]; permiting_comments?: string | null }) => void;
  readonly country: string | null;
  readonly deletePermit: (id: any) => void;
};

function normalizeData(fields: PermitsFormFields, country: string | null): Permit[] {
  const permits: Permit[] = [];

  if (fields.ivaStatus) {
    permits.push({
      id: isBlank(fields.ivaId) ? undefined : fields.ivaId,
      type: 'connection',
      title: fields.ivaTitle ? fields.ivaTitle : country === 'ES' ? 'IVA' : null,
      status: fields.ivaStatus as PermitStatus,
      date: fields.ivaQuarter ? getDateFromQuarterAndYear(fields.ivaQuarter, fields.ivaYear) : null,
    });
  }

  if (fields.diaStatus) {
    permits.push({
      id: isBlank(fields.diaId) ? undefined : fields.diaId,
      type: 'environmental',
      title: fields.diaTitle ? fields.diaTitle : country === 'ES' ? 'DIA' : null,
      status: fields.diaStatus as PermitStatus,
      date: fields.diaQuarter ? getDateFromQuarterAndYear(fields.diaQuarter, fields.diaYear) : null,
    });
  }

  if (fields.aapStatus) {
    permits.push({
      id: isBlank(fields.aapId) ? undefined : fields.aapId,
      type: 'previous',
      title: fields.aapTitle ? fields.aapTitle : country === 'ES' ? 'AAP' : null,
      status: fields.aapStatus as PermitStatus,
      date: fields.aapQuarter ? getDateFromQuarterAndYear(fields.aapQuarter, fields.aapYear) : null,
    });
  }

  if (fields.aacStatus) {
    permits.push({
      id: isBlank(fields.aacId) ? undefined : fields.aacId,
      type: 'construction',
      title: fields.aacTitle ? fields.aacTitle : country === 'ES' ? 'AAC' : null,
      status: fields.aacStatus as PermitStatus,
      date: fields.aacQuarter ? getDateFromQuarterAndYear(fields.aacQuarter, fields.aacYear) : null,
    });
  }

  fields.otherLicenses.forEach((license) => {
    if (license.status && license.title) {
      permits.push({
        id: isBlank(license.id) ? undefined : license.id,
        type: 'other',
        title: license.title,
        status: license.status as PermitStatus,
        date: license.quarter ? getDateFromQuarterAndYear(license.quarter, license.year) : null,
      });
    }
  });

  return permits;
}

const fieldsOtherLicenses = ['title', 'status', 'quarter', 'year'];

export function PermitsForm({ id, technology, onSubmit, country, deletePermit }: PermitsFormProps) {
  const { t } = useTranslation(['projects']);
  const { options: statusOptions } = usePermitStatus();
  const [markedForDeletion, setMarkedForDeletion] = useState<string[]>([]);

  const quarterOptions = [
    { label: 'Q1', value: '1' },
    { label: 'Q2', value: '2' },
    { label: 'Q3', value: '3' },
    { label: 'Q4', value: '4' },
  ];

  const [otherLicenses, setOtherLicenses] = useState<OtherLicenseProps[]>(
    technology.permits
      ?.filter((permit) => permit.type === 'other')
      .map((permit) => ({
        id: permit.id ?? null,
        title: permit.title ?? null,
        status: permit.status ?? null,
        quarter: getQuarterFromDate(permit.date) ?? null,
        year: getYearFromDate(permit.date) ?? null,
      })) || []
  );

  const connectionPermit = technology.permits?.find((permit) => permit.type === 'connection') || null;
  const environmentalPermit = technology.permits?.find((permit) => permit.type === 'environmental') || null;
  const previousPermit = technology.permits?.find((permit) => permit.type === 'previous') || null;
  const constructionPermit = technology.permits?.find((permit) => permit.type === 'construction') || null;

  const options = useMemo(
    () => ({
      defaultValues: {
        ivaId: connectionPermit?.id,
        ivaTitle: connectionPermit?.title,
        ivaStatus: connectionPermit?.status,
        ivaQuarter: getQuarterFromDate(connectionPermit?.date),
        ivaYear: getYearFromDate(connectionPermit?.date),
        diaId: environmentalPermit?.id,
        diaTitle: environmentalPermit?.title,
        diaStatus: environmentalPermit?.status,
        diaQuarter: getQuarterFromDate(environmentalPermit?.date),
        diaYear: getYearFromDate(environmentalPermit?.date),
        aapId: previousPermit?.id,
        aapTitle: previousPermit?.title,
        aapStatus: previousPermit?.status,
        aapQuarter: getQuarterFromDate(previousPermit?.date),
        aapYear: getYearFromDate(previousPermit?.date),
        aacId: constructionPermit?.id,
        aacTitle: constructionPermit?.title,
        aacStatus: constructionPermit?.status,
        aacQuarter: getQuarterFromDate(constructionPermit?.date),
        aacYear: getYearFromDate(constructionPermit?.date),
        otherLicenses,
        permiting_comments: technology.permiting_comments,
      },
    }),
    [connectionPermit, environmentalPermit, previousPermit, constructionPermit, otherLicenses, technology]
  );

  useEffect(() => {

    setOtherLicenses(
      technology.permits
        ?.filter((permit) => permit.type === 'other' && !markedForDeletion.includes(permit.id ?? ''))
        .map((permit) => ({
          id: permit.id ?? null,
          title: permit.title ?? null,
          status: permit.status ?? null,
          quarter: getQuarterFromDate(permit.date) ?? null,
          year: getYearFromDate(permit.date) ?? null,
        })) || []
    );
  }, [technology.permits, markedForDeletion]);

  const handleAddLicense = () => {
    setOtherLicenses((prev) => {
      const newLicense = { id: null, title: null, status: null, quarter: null, year: null };
      const updatedLicenses = [...prev, newLicense];
      return updatedLicenses;
    });
  };

  const handleDeletePermit = (id: any, type: string | null, setValue: any, index?: number) => {
    if (!id || markedForDeletion.includes(id)) return;

    setMarkedForDeletion((prev) => [...prev, id]);

    switch (type) {
      case 'connection':
        setValue('ivaStatus', null);
        setValue('ivaQuarter', null);
        setValue('ivaYear', null);
        break;
      case 'environmental':
        setValue('diaStatus', null);
        setValue('diaQuarter', null);
        setValue('diaYear', null);
        break;
      case 'previous':
        setValue('aapStatus', null);
        setValue('aapQuarter', null);
        setValue('aapYear', null);
        break;
      case 'construction':
        setValue('aacStatus', null);
        setValue('aacQuarter', null);
        setValue('aacYear', null);
        break;
      case 'other':
        if (index !== undefined) {
          fieldsOtherLicenses.forEach((field) => setValue(`otherLicenses[${index}].${field}`, null));
        }
        break;
      default:
        break;
    }
  };

  const handleSave = async (data: any) => {
    try {
      for (const id of markedForDeletion) {
        await deletePermit(id);
      }

      setMarkedForDeletion([]);

      onSubmit(data);
    } catch (error) {
      console.error('Error al guardar o eliminar permisos:', error);
    }
  };

  return (
    <div className="space-y-6">
      <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
        {t(
          'Please detail all the project permits in this section, including connection, environmental, and construction permits. Indicate whether these permits have been obtained or provide their expected timelines.'
        )}
      </Typography>

      <Form<PermitsFormFields>
        id={id}
        onSubmit={(data) =>
          handleSave({ permits: normalizeData(data, country), permiting_comments: data.permiting_comments })
        }
        options={options}
      >
        {({ formState, register, setValue }) => {
          return (
            <div className="space-y-6 divide-y">
              <div className="space-y-3">
                <Typography as="h3" size="body-2" color="primary" weight="semibold">
                  {t(generateLabel(connectionPermit?.title ?? null, 'Connection Permit', country, 'IVA'))}
                </Typography>

                <div className="flex space-x-3">
                  <Input id="ivaId" type="hidden" registration={register('ivaId')} />

                  <Field
                    id="ivaStatus"
                    label={t('Status')}
                    error={formState.errors['ivaStatus']}
                    delay={0}
                    classes={{ field: 'space-y-1 w-1/2' }}
                  >
                    <Select
                      id="ivaStatus"
                      options={statusOptions}
                      placeholder={t('Select an option')}
                      color="primary"
                      registration={register('ivaStatus')}
                    />
                  </Field>

                  <Field id="ivaQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                    <div className="flex gap-2 space-x-3">
                      <Select
                        id="ivaQuarter"
                        options={quarterOptions}
                        placeholder={t('Select a quarter')}
                        color={formState.errors['ivaQuarter'] ? 'error' : 'primary'}
                        registration={register('ivaQuarter')}
                      />
                      <Input
                        id="ivaYear"
                        type="number"
                        placeholder="Add year"
                        step="1"
                        color={formState.errors['ivaQuarter'] ? 'error' : 'primary'}
                        autoComplete="off"
                        registration={register('ivaYear')}
                      />
                    </div>
                  </Field>

                  {connectionPermit && (
                    <div className="flex items-end pb-0.5">
                      <Button
                        color="secondary"
                        icon="trash"
                        onClick={() => handleDeletePermit(connectionPermit?.id, 'connection', setValue)}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-3 pt-6">
                <Typography as="h3" size="body-2" color="primary" weight="semibold">
                  {t(generateLabel(environmentalPermit?.title ?? null, 'Environment permit', country, 'DIA'))}
                </Typography>

                <div className="flex space-x-3">
                  <Input id="diaId" type="hidden" registration={register('diaId')} />

                  <Field
                    id="diaStatus"
                    label={t('Status')}
                    error={formState.errors['diaStatus']}
                    delay={0}
                    classes={{ field: 'space-y-1 w-1/2' }}
                  >
                    <Select
                      id="diaStatus"
                      options={statusOptions}
                      placeholder={t('Select an option')}
                      color="primary"
                      registration={register('diaStatus')}
                    />
                  </Field>

                  <Field
                    id="diaQuarter"
                    label={t('Expected date')}
                    error={formState.errors['diaQuarter']}
                    delay={0}
                    classes={{ field: 'space-y-1 w-1/2' }}
                  >
                    <div className="flex gap-2 space-x-3">
                      <Select
                        id="diaQuarter"
                        options={quarterOptions}
                        placeholder={t('Select a quarter')}
                        color={formState.errors['diaQuarter'] ? 'error' : 'primary'}
                        registration={register('diaQuarter')}
                      />
                      <Input
                        id="diaYear"
                        type="number"
                        placeholder="Add year"
                        step="1"
                        color={formState.errors['diaQuarter'] ? 'error' : 'primary'}
                        autoComplete="off"
                        registration={register('diaYear')}
                      />
                    </div>
                  </Field>
                  {environmentalPermit && (
                    <div className="flex items-end pb-0.5">
                      <Button
                        color="secondary"
                        icon="trash"
                        onClick={() => handleDeletePermit(environmentalPermit?.id, 'environmental', setValue)}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-3 pt-6">
                <Typography as="h3" size="body-2" color="primary" weight="semibold">
                  {t(generateLabel(previousPermit?.title ?? null, 'Previous Permit', country, 'AAP'))}
                </Typography>

                <div className="flex space-x-3">
                  <Input id="aapId" type="hidden" registration={register('aapId')} />

                  <Field
                    id="aapStatus"
                    label={t('Status')}
                    error={formState.errors['aapStatus']}
                    delay={0}
                    classes={{ field: 'space-y-1 w-1/2' }}
                  >
                    <Select
                      id="aapStatus"
                      options={statusOptions}
                      placeholder={t('Select an option')}
                      color="primary"
                      registration={register('aapStatus')}
                    />
                  </Field>
                  <Field id="aapQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                    <div className="flex gap-2 space-x-3">
                      <Select
                        id="aapQuarter"
                        options={quarterOptions}
                        placeholder={t('Select a quarter')}
                        color={formState.errors['aapQuarter'] ? 'error' : 'primary'}
                        registration={register('aapQuarter')}
                      />
                      <Input
                        id="aapYear"
                        type="number"
                        placeholder="Add year"
                        step="1"
                        color={formState.errors['aapQuarter'] ? 'error' : 'primary'}
                        autoComplete="off"
                        registration={register('aapYear')}
                      />
                    </div>
                  </Field>

                  {previousPermit && (
                    <div className="flex items-end pb-0.5">
                      <Button
                        color="secondary"
                        icon="trash"
                        onClick={() => handleDeletePermit(previousPermit?.id, 'previous', setValue)}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-3 pt-6">
                <Typography as="h3" size="body-2" color="primary" weight="semibold">
                  {t(generateLabel(constructionPermit?.title ?? null, 'Construction Permit', country, 'AAC'))}
                </Typography>

                <div className="flex space-x-3">
                  <Input id="aacId" type="hidden" registration={register('aacId')} />

                  <Field
                    id="aacStatus"
                    label={t('Status')}
                    error={formState.errors['aacStatus']}
                    delay={0}
                    classes={{ field: 'space-y-1 w-1/2' }}
                  >
                    <Select
                      id="aacStatus"
                      options={statusOptions}
                      placeholder={t('Select an option')}
                      color="primary"
                      registration={register('aacStatus')}
                    />
                  </Field>

                  <Field id="aacQuarter" label={t('Expected date')} delay={0} classes={{ field: 'space-y-1 w-1/2' }}>
                    <div className="flex gap-2 space-x-3">
                      <Select
                        id="aacQuarter"
                        options={quarterOptions}
                        placeholder={t('Select a quarter')}
                        color={formState.errors['aacQuarter'] ? 'error' : 'primary'}
                        registration={register('aacQuarter')}
                      />
                      <Input
                        id="aacYear"
                        type="number"
                        placeholder="Add year"
                        step="1"
                        color={formState.errors['aacQuarter'] ? 'error' : 'primary'}
                        autoComplete="off"
                        registration={register('aacYear')}
                      />
                    </div>
                  </Field>
                  {constructionPermit && (
                    <div className="flex items-end pb-0.5">
                      <Button
                        color="secondary"
                        icon="trash"
                        onClick={() => handleDeletePermit(constructionPermit?.id, 'construction', setValue)}
                      ></Button>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-y-3 pt-6">
                <Typography as="h3" size="body-2" color="primary" weight="semibold">
                  {t('Other Licenses')}
                </Typography>

                {otherLicenses.map((license, index) => (
                  <div key={license.id ?? `temp-${index}`} className="space-y-3 py-2">
                    <div className="flex">
                      <Field
                        id={`otherLicenses[${index}].title`}
                        label={t('License name')}
                        error={formState.errors?.otherLicenses?.[index]?.title}
                        classes={{ field: 'space-y-1 w-full' }}
                      >
                        <Input
                          type="text"
                          defaultValue={license.title ?? ''}
                          registration={register(`otherLicenses.${index}.title`)}
                          className="w-full"
                        />
                      </Field>

                      {license.id && (
                        <div className="ml-3 flex items-end pb-0.5">
                          <Button
                            color="secondary"
                            icon="trash"
                            onClick={() => handleDeletePermit(license.id, 'other', setValue, index)}
                          ></Button>
                        </div>
                      )}
                    </div>
                    <div className="flex space-x-3">
                      <Field
                        id={`otherLicenses[${index}].status`}
                        label={t('Status')}
                        error={formState.errors?.otherLicenses?.[index]?.status}
                        delay={0}
                        classes={{ field: 'space-y-1 w-1/2' }}
                      >
                        <Select
                          id={`otherLicenses[${index}].status`}
                          options={statusOptions}
                          placeholder={t('Select an option')}
                          color="primary"
                          defaultValue={license.status ?? ''}
                          registration={register(`otherLicenses.${index}.status`)}
                        />
                      </Field>

                      <Field
                        id={`otherLicenses[${index}].quarter`}
                        label={t('Expected date')}
                        delay={0}
                        classes={{ field: 'space-y-1 w-1/2' }}
                      >
                        <div className="flex gap-2 space-x-3">
                          <Select
                            id={`otherLicenses[${index}].quarter`}
                            options={quarterOptions}
                            defaultValue={license.quarter ?? ''}
                            placeholder={t('Select a quarter')}
                            registration={register(`otherLicenses.${index}.quarter`)}
                            color={formState.errors?.otherLicenses?.[index]?.quarter ? 'error' : 'primary'}
                          />
                          <Input
                            placeholder="Add year"
                            id={`otherLicenses[${index}].year`}
                            type="number"
                            step="1"
                            autoComplete="off"
                            defaultValue={license.year?.toString() ?? ''}
                            color={formState.errors?.otherLicenses?.[index]?.year ? 'error' : 'primary'}
                            registration={register(`otherLicenses.${index}.year`)}
                          />
                        </div>
                      </Field>
                    </div>
                    <hr />
                  </div>
                ))}

                <Button color="neutral" onClick={handleAddLicense}>
                  Add more Other licenses
                </Button>
              </div>

              <div>
                <Field
                  id="comments"
                  label={t('Comments')}
                  error={formState.errors['permiting_comments']}
                  delay={0.45}
                  classes={{ field: 'space-y-1 pt-4' }}
                >
                  <TextArea
                    id="comments"
                    color={formState.errors['permiting_comments'] ? 'error' : 'primary'}
                    registration={register('permiting_comments', { setValueAs: nullableStringSetter })}
                  />
                </Field>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
}
