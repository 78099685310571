import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, EmptyState, WorkOfficeDesk } from '@/components';

export function DealsEmptyState({ onResetFilters }: any) {
  const { t } = useTranslation(['Deals']);
  const navigate = useNavigate();

  const resetFilters = () => {
    onResetFilters();
    navigate('/deals?page=1');
  };

  return (
    <EmptyState
      artwork={<WorkOfficeDesk className="w-64" />}
      title={t('Oops! No deals match your criteria')}
      description={t(
        'It seems there are no deals currently available that fit your selected criteria. Try adjusting your filters or check back soon, as new opportunities are added regularly.'
      )}
    >
      <Button onClick={resetFilters} color="primary">
        {t('Reset filters')}
      </Button>
    </EmptyState>
  );
}
