import { useEffect, useState } from 'react';
import { AnimationShell, Button, Typography } from '@/components';
import { useTranslation } from 'react-i18next';
import { Route } from '@/constants';
import { useNavigate } from 'react-router-dom';
import { useTokenParam } from '@/hooks';
import { useVerifyUserMutation } from '@/features/auth';

export function VerifyAccountPage() {
  const { t } = useTranslation(['verify']);
  const navigate = useNavigate();
  const token = useTokenParam();
  const [mutation, { isLoading, isError, isSuccess }] = useVerifyUserMutation();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    if (token && !isLoading && !hasFetched) {
      setHasFetched(true);
      mutation({ token })
        .unwrap()
        .catch((err) => {
          if (err.status === 401) {
            setIsTokenExpired(true);
          }
        });
    }
  }, [token, mutation, hasFetched, isLoading]);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setShowButton(true); // Mostrar el botón después de 1 segundo
      }, 1000);

      return () => clearTimeout(timer); // Limpia el temporizador al desmontar el componente
    }
  }, [isSuccess]);

  const handleButtonClick = () => {
    navigate(Route.SignIn);
  };

  const renderMessage = () => {
    if (isLoading) {
      return t('Verifying your account...');
    }
    if (isTokenExpired) {
      return t('The verification token has expired. Please contact us to activate your account.');
    }
    if (isSuccess) {
      return t('Your account has been verified!');
    }
    if (isError) {
      return t('Verification failed. Please try again later.');
    }
    return '';
  };

  return (
    <div className="w-full space-y-8">
      <div className="space-y-4">
        <Typography as="h1" size="heading-2" color="primary" weight="semibold" className="text-center">
          {renderMessage()}
        </Typography>
        {isSuccess && (
          <Typography size="body-2" color="secondary" className="text-center">
            {t('Please, login to start using your account.')}
          </Typography>
        )}
      </div>
      {isSuccess && showButton && (
        <AnimationShell type="opacity" delay={0.35} className="inline-flex w-full items-center justify-center gap-x-1">
          <Button onClick={handleButtonClick} color="primary" size="lg" className="w-52">
            {t('Login')}
          </Button>
        </AnimationShell>
      )}
    </div>
  );
}
