import { api } from './api';

export * from './types';

export const {
  useLoginMutation,
  useLogoutMutation,
  usePasswordRequestMutation,
  usePasswordResetMutation,
  useRegisterMutation,
  useAcceptInvitationMutation,
  useVerifyUserMutation,
} = api;

export * from './middlewares';

export { api };
