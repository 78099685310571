export enum Route {
  Home = '/',
  Opportunities = '/opportunities',
  Opportunity = '/opportunities/:opportunityId',
  Projects = '/projects',
  Project = '/projects/:projectId',
  Offers = '/offers',
  Offer = '/offers/:offerId',
  Services = '/services',
  Service = '/services/:serviceId',
  Deals = '/deals',
  Deal = '/deals/:dealId',
  Prices = '/prices',
  Transactions = '/transactions',

  ProfileSettings = '/settings/profile',
  CompanySettings = '/settings/company',
  TeamSettings = '/settings/team',
  PlanSettings = '/settings/plans-and-billing',

  SignIn = '/auth/signin',
  SignUp = '/auth/signup',
  SignUpSuccess = '/auth/signup/success',
  VerifyEmailAddress = '/auth/verify',
  InvitationSignUp = '/auth/invitation/signup',
  RecoveryPassword = '/auth/password/recovery',
  RecoveryPasswordSuccess = '/auth/password/recovery/success',
  ResetPassword = '/auth/password/reset',

  TermsAndConditions = '/legal/terms-and-conditions',
  PrivacyPolicy = '/legal/privacy-policy',
  Forbidden = '/forbidden',
}
