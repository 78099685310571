import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Link, Typography } from '@/components';
import { UserForm, UserFormFields, useRegisterMutation } from '@/features/auth';
import { useNotifications } from '@/features/notifications';
import { useRegisterGAPageView } from '@/hooks';

export function SignUpPage() {
  const { t } = useTranslation(['signup']);
  const { add } = useNotifications();
  useRegisterGAPageView('Sign up');

  const [register] = useRegisterMutation();
  const [isRegistered, setIsRegistered] = useState(false);


  const handleSubmit = useCallback(
    (data: UserFormFields) => {
      register(data)
        .unwrap()
        .then(() => {
          setIsRegistered(true);
          add({
            id: new Date().toString(),
            title: t('Account created'),
            description: t('Your account has been created'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t(`There was an error creating the account. Please try again later.`),
          });
        });
    },
    [t, add, register]
  );

  return (
    <div className="w-full space-y-8">
      {isRegistered ? (
        <div className="space-y-4">
          <Typography as="h1" size="heading-1" color="primary" weight="semibold" className="text-center">
            {t('Check your email inbox to verify your account')}
          </Typography>
          <Typography size="body-2" color="secondary" className="text-center">
            {t(
              "You're just one step away from accessing dozens of renewable energy projects, advisors and market insights."
            )}
          </Typography>
        </div>
      ) : (
        <>
          <Typography as="h1" size="heading-1" color="primary" weight="semibold">
            {t(`Create your account`)}
          </Typography>

          <UserForm onSubmit={handleSubmit} />
        </>
      )}
      <AnimationShell type="opacity" delay={0.35} className="inline-flex w-full items-center justify-center gap-x-1">
        <Typography weight="normal" size="body-2">
          {t(`Need help?`)}
        </Typography>
        Email us at <Link to="mailto:clients@nteaser.com">clients@nteaser.com</Link>
      </AnimationShell>
    </div>
  );
}
