import { useSessionTimeout } from '@/features/auth/hooks/use-session-manager';
import { useLogout } from '@/features/auth/hooks/use-logout';

export function SessionManager() {
  const logout = useLogout();

  useSessionTimeout(() => {
    logout();
  });

  return null;
}
