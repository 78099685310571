import { useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { CapacityFilter } from './capacity';
import { CountryFilter } from './countries';
import { DevelopmentStatusFilter } from './development-status';
import { MyOpportunitiesFilter } from './my-opportunities';
import { SearchFilter } from './search';
import { TechnologyFilter } from './technology';
import { Button } from '@/components';

export type OpportunitiesFilterType = {
  readonly type?: 'deals' | 'opportunities';
  readonly onResetFilters: () => void;
};

export function OpportunitiesFilter({ type, onResetFilters }: OpportunitiesFilterType) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = useCallback(
    (field: string, rawValue: string | string[]) => {
      const value = Array.isArray(rawValue) ? rawValue.join(',') : rawValue.trim();

      setSearchParams((searchParams) => {
        if (value.length === 0) {
          searchParams.delete(field);
        } else {
          searchParams.set(field, value);
        }

        searchParams.set('page', '1');
        return searchParams;
      });
    },
    [setSearchParams]
  );

  const resetFilters = () => {
    const path = type === 'deals' ? '/deals' : '/';

    onResetFilters();
    navigate(`${path}?page=1`);
  };

  return (
    <div className="flex flex-col space-y-6">
      <SearchFilter defaultValue={searchParams.get('search') ?? ''} onChange={(value) => onChange('search', value)} />
      <TechnologyFilter
        defaultValue={searchParams.get('technology')?.split(',') ?? []}
        onChange={(value) => onChange('technology', value)}
        type={type}
      />
      {type !== 'deals' && (
        <DevelopmentStatusFilter
          value={searchParams.get('development_status') ?? ''}
          onChange={(value) => onChange('development_status', value)}
        />
      )}
      <CountryFilter value={searchParams.get('country') ?? ''} onChange={(value) => onChange('country', value)} />
      {type !== 'deals' && (
        <>
          <CapacityFilter
            defaultValue={searchParams.get('capacity')?.split(',') ?? []}
            onChange={(value) => onChange('capacity', value)}
          />

          <hr className="mx-auto h-px w-full bg-neutral-50" />

          <MyOpportunitiesFilter
            defaultValue={searchParams.get('company') ?? ''}
            onChange={(value) => onChange('company', value ? 'me' : '')}
          />
        </>
      )}

      <Button color="primary" onClick={resetFilters} className="self-start">
        Reset Filters
      </Button>
    </div>
  );
}
