import { useTranslation } from 'react-i18next';

import { Field, Select } from '@/components';
import { useDevelopmentStatus } from '@/hooks';

export type DevelopmentStatusFilterProps = {
  readonly value?: string;
  readonly onChange: (value: string) => void;
};

export function DevelopmentStatusFilter({ value, onChange }: DevelopmentStatusFilterProps) {
  const { t } = useTranslation(['home']);

  const { options: developmentStatus } = useDevelopmentStatus();

  return (
    <Field id="development_status" label={t(`Development status`)} classes={{ field: 'space-y-2' }}>
      <Select
        id="development_status"
        size="md"
        color="primary"
        placeholder={t(`Any status`)}
        options={developmentStatus}
        value={value}
        registration={{
          name: 'developmentStatus',
          onChange: (event) => {
            onChange(event.target.value);
            return Promise.resolve();
          },
        }}
      />
    </Field>
  );
}
