import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Aside, Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { DealsContent } from '@/features/deals';
import { OpportunitiesFilter } from '@/features/opportunities';

export function Deals() {
  const { t } = useTranslation(['deals']);
  useBreadcrumbs(() => [{ title: t(`Other Deals`) }]);

  const [resetKey, setResetKey] = useState(0);

  const handleResetFilters = () => {
    setResetKey((prev) => prev + 1);
  };

  return (
    <div className="inline-flex h-full w-full items-start justify-start overflow-hidden">
      <Aside>
        <OpportunitiesFilter type={'deals'} key={resetKey} onResetFilters={handleResetFilters} />
      </Aside>
      <div className="mx-auto h-full w-full overflow-x-auto bg-neutral-25 py-8">
        <Container className="mx-auto w-fit" overflow={true}>
          <DealsContent onResetFilters={handleResetFilters} />
        </Container>
      </div>
    </div>
  );
}
