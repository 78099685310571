import * as Sentry from '@sentry/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Route as RoutePath } from '@/constants';

import {
  AppPage,
  CompanySettingsPage,
  Deal,
  Deals,
  MyOffers,
  MyOpportunities,
  // MyProjects,
  Opportunities,
  Opportunity,
  PlanSettingsPage,
  ProfileSettingsPage,
  Project,
  Service,
  Services,
  // Project,
  TeamSettingsPage,
  Prices,
  Transactions,
} from './app';
import { Offer } from './app/offer/offer';
import { AuthPage, SignInPage, SignUpInvitationPage, SignUpPage, VerifyAccountPage } from './auth';
import { RecoveryPasswordPage, RecoveryPasswordSuccessPage, ResetPasswordPage } from './auth/password';
import { ForbiddenPage } from './forbidden';
import { LegalPage, PrivacyPolicy, TermsAndConditions } from './legal';
import { NotFoundPage } from './not-found';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function Router() {
  return (
    <BrowserRouter basename="/">
      <SentryRoutes>
        <Route path="auth" element={<AuthPage />}>
          <Route path={RoutePath.SignIn} element={<SignInPage />} />
          <Route path={RoutePath.SignUp} element={<SignUpPage />} />
          <Route path={RoutePath.InvitationSignUp} element={<SignUpInvitationPage />} />
          <Route path={RoutePath.RecoveryPassword} element={<RecoveryPasswordPage />} />
          <Route path={RoutePath.RecoveryPasswordSuccess} element={<RecoveryPasswordSuccessPage />} />
          <Route path={RoutePath.ResetPassword} element={<ResetPasswordPage />} />
          <Route path={RoutePath.VerifyEmailAddress} element={<VerifyAccountPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route element={<AppPage />}>
          <Route path={RoutePath.Home} element={<Opportunities />} />

          {/* TODO-PROJECTS : REMOVE LINKS TO PROJECTS */}
          {/* <Route path={RoutePath.Projects} element={<MyProjects />} /> */}
          <Route path={RoutePath.Project} element={<Project />} />

          <Route path={RoutePath.Opportunities} element={<MyOpportunities />} />
          <Route path={RoutePath.Opportunity} element={<Opportunity />} />

          <Route path={RoutePath.Offers} element={<MyOffers />} />
          <Route path={RoutePath.Offer} element={<Offer />} />

          <Route path={RoutePath.Services} element={<Services />} />
          <Route path={RoutePath.Service} element={<Service />} />

          <Route path={RoutePath.Deals} element={<Deals />} />
          <Route path={RoutePath.Deal} element={<Deal />} />

          <Route path={RoutePath.Prices} element={<Prices />} />
          <Route path={RoutePath.Transactions} element={<Transactions />} />

          <Route path={RoutePath.ProfileSettings} element={<ProfileSettingsPage />} />
          <Route path={RoutePath.CompanySettings} element={<CompanySettingsPage />} />
          <Route path={RoutePath.TeamSettings} element={<TeamSettingsPage />} />
          <Route path={RoutePath.PlanSettings} element={<PlanSettingsPage />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route element={<LegalPage />}>
          <Route path={RoutePath.TermsAndConditions} element={<TermsAndConditions />} />
          <Route path={RoutePath.PrivacyPolicy} element={<PrivacyPolicy />} />

          <Route path={RoutePath.Forbidden} element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </SentryRoutes>
    </BrowserRouter>
  );
}
